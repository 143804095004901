
import { defineComponent } from 'vue'

import CloseIcon from '@/assets/svg/close-icon.svg?inline'
import MainButton from '@/components/atoms/buttons/MainButton.vue'

export default defineComponent({
  name: 'Popup',
  data () {
    return {
      translateData: {}
    }
  },
  props: {
    onClose: {
      type: Function,
      default () {
        return {}
      }
    },
    onDelete: {
      type: Function,
      default () {
        return {}
      }
    }
  },
  mounted () {
    // this.translate()
  },
  components: {
    CloseIcon,
    MainButton
  },
  methods: {
    translate (data) {
      data = []
      data.push()
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    handleCloseModal () {
      this.onClose()
    },
    handleDelete () {
      this.onDelete()
    }
  }
})
