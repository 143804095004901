import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d092636"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "settings-section"
}
const _hoisted_2 = { class: "settings-section__title" }
const _hoisted_3 = { class: "settings-section__second-title" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "spinner-border spinner-border-sm" }
const _hoisted_8 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_9 = {
  key: 0,
  class: "settings-section__success"
}
const _hoisted_10 = { class: "settings-section__second-title" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "spinner-border spinner-border-sm" }
const _hoisted_17 = {
  key: 0,
  class: "settings-section__success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_PopupDeleteAccount = _resolveComponent("PopupDeleteAccount")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(this.translateData.settings), 1),
        _createVNode(_component_Form, {
          onSubmit: _ctx.changeName,
          "validation-schema": _ctx.schema,
          class: "settings-section__form"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(this.translateData.change_username), 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Field, {
                name: "name",
                type: "text",
                placeholder: "Ваше имя",
                class: "form-control"
              }),
              _createVNode(_component_ErrorMessage, {
                name: "name",
                class: "error-feedback"
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                class: "settings-section__button",
                disabled: _ctx.loading
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_7, null, 512), [
                  [_vShow, _ctx.loading]
                ]),
                _createElementVNode("span", null, _toDisplayString(this.translateData.save_button), 1)
              ], 8, _hoisted_6)
            ]),
            _createElementVNode("div", null, [
              (_ctx.message)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.message), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.changeNameSuccess)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Имя успешно изменено!"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"]),
        _createVNode(_component_Form, {
          onSubmit: _ctx.changePassword,
          "validation-schema": _ctx.schemaPassword,
          class: "settings-section__form"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, _toDisplayString(this.translateData.change_password), 1),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Field, {
                name: "current_password",
                type: "password",
                placeholder: "Старый пароль",
                class: "form-control"
              }),
              _createVNode(_component_ErrorMessage, {
                name: "current_password",
                class: "error-feedback"
              })
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_Field, {
                name: "new_password",
                type: "password",
                placeholder: "Новый пароль",
                class: "form-control"
              }),
              _createVNode(_component_ErrorMessage, {
                name: "new_password",
                class: "error-feedback"
              })
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_Field, {
                name: "password_confirmation",
                type: "password",
                placeholder: "Повторите пароль",
                class: "form-control"
              }),
              _createVNode(_component_ErrorMessage, {
                name: "password_confirmation",
                class: "error-feedback"
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("button", {
                class: "settings-section__button",
                disabled: _ctx.loading
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_16, null, 512), [
                  [_vShow, _ctx.loading]
                ]),
                _createElementVNode("span", null, _toDisplayString(this.translateData.save_button), 1)
              ], 8, _hoisted_15)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_AlertBlock, {
                message: _ctx.error?.password
              }, null, 8, ["message"])
            ]),
            (_ctx.changePasswordSuccess)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, "Пароль успешно изменен!"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"]),
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOpenDeleteModal && _ctx.handleOpenDeleteModal(...args))),
          class: "settings-section__delete-account"
        }, " Удалить аккаунт "),
        (_ctx.deleteModal)
          ? (_openBlock(), _createBlock(_component_PopupDeleteAccount, {
              key: 0,
              onClose: _ctx.handleCloseDeleteModal,
              onDelete: _ctx.handleDeleteAccount
            }, null, 8, ["onClose", "onDelete"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}