
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

import AlertBlock from '@/components/molecules/AlertBlock.vue'
import PopupDeleteAccount from '@/components/molecules/PopupDeleteAccount.vue'

export default defineComponent({
  name: 'SettingsSection',
  data () {
    const schema = yup.object().shape({
      name: yup.string().required('Login is required!')
    })
    const schemaPassword = yup.object().shape({
      current_password: yup.string().required('Старый пароль'),
      new_password: yup.string().required('Новый пароль'),
      password_confirmation: yup.string().required('Повторить пароль')
    })

    return {
      changeNameSuccess: false,
      changePasswordSuccess: false,
      loading: false,
      message: '',
      error: {},
      deleteModal: false,
      schema,
      translateData: {},
      schemaPassword
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertBlock,
    PopupDeleteAccount
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('settings', 'change_username', 'save_button', 'change_password')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    changeName (data) {
      this.$store.dispatch('getPayments/updateProfile', data).then(
        (response) => {
          localStorage.setItem('userData', JSON.stringify(response))
          this.changeNameSuccess = true
          setTimeout(() => {
            this.changeNameSuccess = false
          }, 5000)
        },
        (error) => {
          console.log(error.name)
        }
      )
    },
    changePassword (data) {
      this.$store.dispatch('auth/changePassword', data).then(
        (response) => {
          console.log(response)
          this.changePasswordSuccess = true
          setTimeout(() => {
            this.changePasswordSuccess = false
          }, 5000)
        },
        (error) => {
          const e = error.response.data
          this.error.password = e.errors.new_password[0]
        }
      )
    },
    handleCloseDeleteModal () {
      this.deleteModal = false
    },
    handleOpenDeleteModal () {
      this.deleteModal = true
    },
    handleDeleteAccount () {
      this.deleteModal = false
      this.$store.dispatch('getPayments/deleteProfile').then(
        (response) => {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push('/')
          })
        },
        (error) => {
          console.log(error.name)
        }
      )
    }
  }
})
